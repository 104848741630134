<template>
    <v-container>
      <vue-reader
        :url="require('@/assets/CrimePunishment-EPUB3.epub')"
        :epubOptions="{
          manager: 'continuous',
        }"
        :getRendition="getRendition"
        :class="themeName"
      >
      
      </vue-reader>
      

      
    <v-speed-dial
      v-model="fab"
      :top="top"
      :bottom="bottom"
      :right="right"
      :left="left"
      :direction="direction"
      :open-on-hover="hover"
      :transition="transition"
      absolute
      class="text-right"
      style="width: 300px;" 
    >
      <template v-slot:activator>
        <v-btn
          v-model="fab"
          dark
          fab
      class="overflow-visible"

        >
          <v-icon v-if="fab">
            mdi-close
          </v-icon>
          <v-icon v-else>
            mdi-pencil
          </v-icon>
        </v-btn>
      </template>
      <v-card width="300px"
      style="right: 0px; bottom:0"
      @click.stop=""
      :ripple="false"
      >
        <v-card-title class="text-body-1">
          뷰어 설정
        </v-card-title>
        <v-card-text>
          <!-- <v-subheader> -->
            <div class="d-flex align-center justify-space-between" style="width: 100%;">
              열람 방식
              <div>
                <v-btn text small class="mx-1 px-1" >페이지</v-btn>
                <v-btn text small class="mx-1 px-1" disabled>스크롤</v-btn>
              </div>
            </div>
          <!-- </v-subheader> -->
           
          <div class="d-flex align-center justify-space-between" style="width: 100%;">
              테마
              <div>
                <v-btn fab x-small elevation=0 class="mx-1">가</v-btn>
                <v-btn fab x-small elevation=0 class="mx-1" color="primary">가</v-btn>
                <v-btn fab x-small elevation=0 class="mx-1" color="amber lighten-3" style="color: black;">가</v-btn>
                <v-btn fab x-small elevation=0 class="mx-1" color="green darken-3">가</v-btn>
              </div>
            </div>
          
          <div class="d-flex align-center justify-space-between" style="width: 100%;">
            글꼴
            <div>
              <v-btn text small class="mx-1 px-1" disabled>고덕체</v-btn>
              <v-btn text small class="mx-1 px-1">바탕체</v-btn>
              <v-btn text small class="mx-1 px-1" disabled>명조체</v-btn>
            </div>
          </div>
          
          <div class="d-flex align-center justify-space-between" style="width: 100%;">
              글자 크기
              <div>
                <v-btn icon outlined small>-</v-btn>
                <span class="px-2"> 4 </span>
                <v-btn icon outlined small >+</v-btn>
              </div>
            </div>
          
          <div class="d-flex align-center justify-space-between" style="width: 100%;">
              줄 간격
              <div>
                <v-btn icon outlined small>-</v-btn>
                <span class="px-2"> 2 </span>
                <v-btn icon outlined small >+</v-btn>
              </div>
            </div>
          
          <div class="d-flex align-center justify-space-between" style="width: 100%;">
              여백
              <div>
                <v-btn icon outlined small disabled>-</v-btn>
                <span class="px-2"> 1 </span>
                <v-btn icon outlined small >+</v-btn>
              </div>
            </div>

            <div class="d-flex justify-center">
              <v-btn text class=" text-caption secondary--text" small>보기 설정 초기화 
                <v-icon small class="ml-1">mdi-arrow-u-left-top</v-icon>
              </v-btn>
            </div>

            
          <div class="d-flex align-center justify-space-between" style="width: 100%;">
              페이지 두쪽 보기
              <div>
                <v-switch></v-switch>
              </div>
            </div>
        </v-card-text>
      </v-card>
    </v-speed-dial>
    </v-container>
  </template>

  <script>
  import { VueReader } from 'vue-reader'
  export default {
    data(){
        return{
            rendition:null,

            direction: 'top',
      fab: false,
      fling: false,
      hover: false,
      tabs: null,
      top: false,
      right: true,
      bottom: true,
      left: false,
      transition: 'slide-y-reverse-transition',
        }
    },
    methods: {
      getRendition(rendition) {
        this.rendition = rendition
        console.log(this.rendition)
        this.updateTheme(rendition, this.$vuetify.theme.dark ? 'dark' : 'light')
      },
      updateTheme(rendition, theme) {
        const themes = rendition.themes
        switch (theme) {
            case 'dark': {
            themes.override('color', '#fff')
            themes.override('background', '#000')
            break
            }
            case 'light': {
            themes.override('color', '#000')
            themes.override('background', '#fff')
            break
            }
        }
        }
    },
    computed:{
        themeName(){
            return (this.$vuetify.theme.dark ? 'dark' : 'light')+'-theme'
        }
    },
    watch:{
        themeName(newVal){
            this.updateTheme(this.rendition,newVal.split('-')[0])
        },
    },
    components: {
      VueReader,
    },
  }
  </script>

<style>
/* .v-speed-dial__list{
  width: 300px;
} */

.tocArea{
    margin:15px;
    padding:0;

}

.theme {
  position: absolute;
  bottom: 1rem;
  right: 1rem;
  left: 1rem;
  z-index: 1;
  text-align: center;
}

.dark-theme .readerArea {
  background-color: #000 !important;
}

.dark-theme .titleArea {
  color: #ccc;
}

.dark-theme .tocButtonExpanded {
  color: #222;
}

.dark-theme .tocButtonBar {
  background: #fff;
}

.dark-theme .tocButton {
  color: white;
}
.dark-theme .tocArea {
  background-color: #111;
}

.dark-theme .readerArea .arrow {
  color: white;
}

.dark-theme .readerArea .arrow:hover {
  color: #ccc;
}

</style>