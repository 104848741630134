<!-- Main, 1-6 -->
<template>
  <div id="home" class="pa-4 pt-0">
    <v-container fluid class="pa-1">
      
  <div class="ml-2 mb-1">
    <v-btn-toggle v-model="content_type " group  style="width:100%; flex-wrap: wrap;">
      <v-btn
        class="ma-2"
        small
        label
        value="all"
      >
        전체
      </v-btn>
      <v-btn
        class="ma-2"
        small
        label
        value="comics"
      >
        만화
      </v-btn>
      <v-btn
        class="ma-2"
        small
        label
        value="webtoon"
      >
        웹툰
      </v-btn>
      <v-btn
        class="ma-2"
        small
        label
        value="novel"
      >
        소설
      </v-btn>
      <v-btn
        class="ma-2"
        small
        label
        value="magazine"
      >
        매거진
      </v-btn>
      <v-btn
        class="ma-2"
        small
        label
        value="news"
      >
        뉴스
      </v-btn>
      <v-btn
        class="ma-2"
        small
        label
        value="recent"
      >
        최신 업데이트
      </v-btn>

  </v-btn-toggle>
  
  <v-container 
    v-if="content_type === 'all'">
    <v-card
      class="content-bg card mx-auto"
      max-width="500"
      flat  
      outlined
      router
      to="/watch/12">
      
      <div class="d-flex justify-space-between align-center">

        <v-img
          :src="require('@/assets/thumbnails/thumbnail_06.jpg')"
          max-width="250"
          max-height="200"
          contain
          class="rounded ma-2"
        ></v-img>

        <v-row no-gutters class="align-center">
          <v-col cols="2" class="hidden-sm-and-down">
            <v-list-item class="pl-0" router :to="'/channels/12'">
              <v-list-item-avatar color="grey lighten-3">
                <v-img class="elevation-6" :src="require('@/assets/grape_logo_only.png')"></v-img>
              </v-list-item-avatar>
            </v-list-item>
          </v-col>
          <v-col>
            <v-card-title class="pl-2 pt-3 subtitle-1 font-weight-bold">
              원피스 최신작!!!
            </v-card-title>

            <v-card-subtitle class="pl-2 pb-0">
              CMOA
            </v-card-subtitle>
          </v-col>
        </v-row>

      </div>

    </v-card>
  </v-container>

<!-- <v-btn color="success" v-if="test">text</v-btn> -->
  </div>
      <v-row>
        <v-col
          cols="12"
          sm="6"
          md="4"
          lg="3"
          v-for="(d,i) in getActiveContents(content_type)"
          :key="i"
          class="mx-xs-auto"
        >
          <v-skeleton-loader type="card-avatar" :loading="loading" style="height: 100%;">
            <video-card
              :card="{ maxWidth: 350 }"
              :video="d.video"
              :channel="d.channel"
            ></video-card>
          </v-skeleton-loader>
        </v-col>

        <!-- <v-col
          cols="12"
          sm="6"
          md="4"
          lg="3"
          v-for="i in loading ? 10 : 12"
          :key="i"
          class="mx-xs-auto"
        >
          <v-skeleton-loader type="card-avatar" :loading="loading">
            <video-card
              :card="{ maxWidth: 350 }"
              :video="video"
              :channel="channel"
            ></video-card> -->
            <!-- <v-card
              class="content-bg card mx-auto"
              max-width="350"
              flat
              tile
              router
              to="/watch/12"
            >
              <v-img
                src="https://cdn.vuetifyjs.com/images/cards/sunshine.jpg"
                height="200px"
              ></v-img>
              <v-row no-gutters>
                <v-col cols="2">
                  <v-list-item class="pl-0 pt-3" router to="/channels/12">
                    <v-list-item-avatar color="grey darken-3">
                      <v-img
                        class="elevation-6"
                        src="https://randomuser.me/api/portraits/men/1.jpg"
                      ></v-img>
                    </v-list-item-avatar>
                  </v-list-item>
                </v-col>
                <v-col>
                  <v-card-title class="pl-2 pt-3 subtitle-1 font-weight-bold">
                    Top western road trips
                  </v-card-title>

                  <v-card-subtitle class="pl-2 pb-0">
                    1,000 miles of wonder
                  </v-card-subtitle>
                  <v-card-subtitle class="pl-2 pt-0">
                    9.6k views<v-icon>mdi-circle-small</v-icon>6 시간 전
                  </v-card-subtitle>
                </v-col>
              </v-row>
            </v-card> -->
            
          <!-- </v-skeleton-loader>
        </v-col> -->
        
      </v-row>
    </v-container>
  </div>
</template>

<script>
import videoCard from '@/components/VideoCard'

export default {
  name: 'Home',
  data: () => ({
    content_type: 'all',
    loading: true,
    video: {
      url: '/watch/12',
        thumb: require('@/assets/thumbnails/thumbnail_06.jpg'),
        title: '원피스 전세계 언어 번역 최신화!!',
      views: '96만 회',
      createdAt: '6 시간 전'
    },
    channel: {
      url: '/channels/12',
      avatar: 'https://randomuser.me/api/portraits/men/1.jpg'
    },
    dummies:[
    //   {
    //   video: {
    //     url: '/watch/12',
    //     thumb: 'https://cdn.vuetifyjs.com/images/cards/sunshine.jpg',
    //     title: 'ㅇㅇㅇㅇㅇㅇㅇ',
    //     views: '96만 회',
    //     createdAt: '6 시간 전'
    //   },
    //   channel: {
    //     url: '/channels/12',
    //     avatar: 'https://randomuser.me/api/portraits/men/1.jpg'
    //   },
    //   tags: ['webtoon', 'news','recent']
    // },
    {
      video: {
        url: '/watch/01',
        thumb: require('@/assets/thumbnails/brave_thumb.png'),
        title: '용기 전세계 언어 번역 최신화!!',
        views: '96만 회',
        createdAt: '6 시간 전'
      },
      channel: {
        url: '/channels/12',
        avatar: require('@/assets/avatars/avatar_01.png'),
      },
      tags: ['comics', 'recent']
    },
    {
      video: {
        url: '/watch/01?content=novel',
        thumb: require('@/assets/thumbnails/cnp_thumb.png'),
        title: '죄와 벌 전세계 언어 번역 최신화!!',
        views: '96만 회',
        createdAt: '6 시간 전'
      },
      channel: {
        url: '/channels/12',
        avatar: require('@/assets/avatars/avatar_02.jpg'),
      },
      tags: ['novel', 'recent']
    },
    {
      video: {
        url: '/watch/01?content=magazine',
        thumb: require('@/assets/thumbnails/bnb_thumb.png'),
        title: 'Bird and Blooms 전세계 언어 번역 최신화!!',
        views: '96만 회',
        createdAt: '6 시간 전'
      },
      channel: {
        url: '/channels/12',
        avatar: require('@/assets/avatars/avatar_03.png'),
      },
      tags: ['magazine', 'news' , 'recent']
    },
    {
      video: {
        url: '/watch/01',
        thumb: require('@/assets/thumbnails/thumbnail_01.jpg'),
        title: '킹덤 전세계 언어 번역 최신화!!',
        views: '96만 회',
        createdAt: '6 시간 전'
      },
      channel: {
        url: '/channels/12',
        avatar: require('@/assets/avatars/avatar_04.png'),
      },
      tags: ['comics', 'recent']
    },
    {
      video: {
        url: '/watch/01',
        thumb: require('@/assets/thumbnails/thumbnail_02.jpg'),
        title: '쿵푸판다 4 전세계 언어 번역 최신화!!',
        views: '96만 회',
        createdAt: '6 시간 전'
      },
      channel: {
        url: '/channels/12',
        avatar: require('@/assets/avatars/avatar_05.png'),
      },
      tags: ['comics', ]
    },
    {
      video: {
        url: '/watch/01',
        thumb: require('@/assets/thumbnails/thumbnail_03.png'),
        title: '귀멸의 칼날 전세계 언어 번역 최신화!!',
        views: '96만 회',
        createdAt: '6 시간 전'
      },
      channel: {
        url: '/channels/12',
        avatar: require('@/assets/avatars/avatar_06.png'),
      },
      tags: ['comics', 'recent']
    },
    {
      video: {
        url: '/watch/01',
        thumb: require('@/assets/thumbnails/thumbnail_04.jpg'),
        title: '해리포터 전세계 언어 번역 최신화!!',
        views: '96만 회',
        createdAt: '6 시간 전'
      },
      channel: {
        url: '/channels/12',
        avatar: require('@/assets/avatars/avatar_07.png'),
      },
      tags: ['novel', ]
    },
    {
      video: {
        url: '/watch/01',
        thumb: require('@/assets/thumbnails/thumbnail_05.jpg'),
        title: '나혼자만 레벨업 전세계 언어 번역 최신화!!',
        views: '96만 회',
        createdAt: '6 시간 전'
      },
      channel: {
        url: '/channels/12',
        avatar: require('@/assets/avatars/avatar_08.png'),
      },
      tags: ['webtoon', 'recent']
    },
    {
      video: {
        url: '/watch/01',
        thumb: require('@/assets/thumbnails/thumbnail_06.jpg'),
        title: '원피스 전세계 언어 번역 최신화!!',
        views: '96만 회',
        createdAt: '6 시간 전'
      },
      channel: {
        url: '/channels/12',
        avatar: require('@/assets/avatars/avatar_09.png'),
      },
      tags: ['comics',]
    },
    {
      video: {
        url: '/watch/01',
        thumb: require('@/assets/thumbnails/thumbnail_07.jpg'),
        title: '타임즈 전세계 언어 번역 최신화!!',
        views: '96만 회',
        createdAt: '6 시간 전'
      },
      channel: {
        url: '/channels/12',
        avatar: require('@/assets/avatars/avatar_11.png'),
      },
      tags: ['magazine', 'recent']
    },
    {
      video: {
        url: '/watch/01',
        thumb: require('@/assets/thumbnails/thumbnail_08.jpg'),
        title: '오베라는 남자 전세계 언어 번역 최신화!!',
        views: '96만 회',
        createdAt: '6 시간 전'
      },
      channel: {
        url: '/channels/12',
        avatar: require('@/assets/avatars/avatar_12.png'),
      },
      tags: ['novel',]
    },
    {
      video: {
        url: '/watch/01',
        thumb: require('@/assets/thumbnails/thumbnail_09.jpg'),
        title: '노인과 바다 전세계 언어 번역 최신화!!',
        views: '96만 회',
        createdAt: '6 시간 전'
      },
      channel: {
        url: '/channels/12',
        avatar: require('@/assets/avatars/avatar_13.png'),
      },
      tags: ['novel', 'recent']
    },
    {
      video: {
        url: '/watch/01',
        thumb: require('@/assets/thumbnails/thumbnail_10.jpg'),
        title: '빨강 머리 앤 전세계 언어 번역 최신화!!',
        views: '96만 회',
        createdAt: '6 시간 전'
      },
      channel: {
        url: '/channels/12',
        avatar: require('@/assets/avatars/avatar_14.png'),
      },
      tags: ['novel',]
    },
    {
      video: {
        url: '/watch/01',
        thumb: require('@/assets/thumbnails/thumbnail_11.jpg'),
        title: '슬램덩크 전세계 언어 번역 최신화!!',
        views: '96만 회',
        createdAt: '6 시간 전'
      },
      channel: {
        url: '/channels/12',
        avatar: require('@/assets/avatars/avatar_15.png'),
      },
      tags: ['comics', 'recent']
    },
    {
      video: {
        url: '/watch/01',
        thumb: require('@/assets/thumbnails/thumbnail_12.jpg'),
        title: '배트맨 전세계 언어 번역 최신화!!',
        views: '96만 회',
        createdAt: '6 시간 전'
      },
      channel: {
        url: '/channels/12',
        avatar:require('@/assets/avatars/avatar_10.jpg'),
      },
      tags: ['comics',]
    },
  ],
  }),
  components: {
    videoCard
  },
  mounted() {
    this.loading = true;
    setTimeout(() => {
      this.loading = false
    }, 1000)
  },
  methods: {
    getActiveContents(type) {
      return type ==='all' ? this.dummies : this.dummies.filter(d => d.tags.includes(type))
    }
  }
}
</script>

<style lang="scss">
.card {
  // background: #f9f9f9 !important;
}
.v-item--active{
  color:#5c2e91 !important;
}
</style>
