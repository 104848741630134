<template>
  <div class="ml-2">
    <!-- <v-btn-toggle v-model="toggle_exclusive"> -->
      <v-btn
        class="ma-2"
        small
        label
        
      >
        전체
      </v-btn>
      <v-btn
        class="ma-2"
        small
        label
      >
        만화
      </v-btn>
      <v-btn
        class="ma-2"
        small
        label
      >
        웹툰
      </v-btn>
      <v-btn
        class="ma-2"
        small
        label
      >
        소설
      </v-btn>
      <v-btn
        class="ma-2"
        small
        label
      >
        매거진
      </v-btn>
      <v-btn
        class="ma-2"
        small
        label
      >
        뉴스
      </v-btn>
      <v-btn
        class="ma-2"
        small
        label
      >
        최신 업데이트
      </v-btn>

  <!-- </v-btn-toggle> -->
  <v-divider></v-divider>
  
  <div>
    <v-btn color="accent" large @click.stop="sdialog = true"
      >Open SocialDialog</v-btn
    >
    <social-dialog v-model="sdialog"></social-dialog>
    <v-btn color="accent" large @click.stop="tdialog = true"
      >Open TransDialog</v-btn
    >
    <translate-dialog v-model="tdialog"></translate-dialog>
    <v-btn color="accent" large @click.stop="rdialog = true"
      >Open ReportDialog</v-btn
    >
    <report-dialog v-model="rdialog"></report-dialog>
    <v-btn color="accent" large @click.stop="pldialog = true"
      >Open SavePlayListDialog</v-btn
    >
    <save-play-list-dialog v-model="pldialog"></save-play-list-dialog>
  </div>

  <v-divider></v-divider>
  <div>
    <v-btn color="accent" large @click.stop="ddialog = true"
      >Open DonateDialog</v-btn
    >
    <donate-dialog v-model="ddialog"></donate-dialog>
  </div>
  <v-divider></v-divider>
  <div>
    <v-btn color="accent" large @click.stop="cdialog = true"
      >Open DonateDialog</v-btn
    >
    <channel-details-dialog v-model="cdialog"></channel-details-dialog>
  </div>
  </div>
</template>

<script>
import SocialDialog from '@/components/dialogs/SocialDialog.vue';
import TranslateDialog from '@/components/dialogs/TranslateDialog.vue';
import ReportDialog from '@/components/dialogs/ReportDialog.vue';
import SavePlayListDialog from '@/components/dialogs/SavePlayListDialog.vue';
import DonateDialog from '@/components/dialogs/DonateDialog.vue';
import ChannelDetailsDialog from '@/components/dialogs/ChannelDetailsDialog.vue';

export default {
  data() {
    return {
      sdialog: false,
      tdialog: false,
      rdialog: false,
      pldialog: false,
      ddialog: false,      
      cdialog: false,      
    };
  },
  components: {
    SocialDialog,
    TranslateDialog,
    ReportDialog,
    SavePlayListDialog,
    DonateDialog,
    ChannelDetailsDialog
  },
};
</script>

<style>

</style>